import { ComponentHelper } from "../../core/ComponentHelper";
import Modules from "../../core/Modules";
import { styled } from "../../core/core";

let id = 1;

    styled.css `
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        align-content: center;
        justify-content: space-between;
        padding: 10px;
        margin: 10px;
        border-radius: 10px;
        align-items: center;
        justify-items: stretch;
        flex-wrap: wrap;
        & > * {
            display: inline-block;
            & > img {
                max-height: 20vh;
                margin: 20px;
                box-shadow: 0 0 20px 1px black;
                border: solid 1px white;
            }
            padding: 5px;
            &:hover > img {
                box-shadow: 0 0 20px 1px blue;
            }
        }

        @media (max-width: 500px) {
            & > * {
                & > img {
                    max-width: 25vh;
                }
            }
        }
    `.installGlobal("image-grid");

class ImageGrid extends HTMLElement {

    connectedCallback() {
        this.id ||= `img_grid_${id++}`;
        this.prepare().catch(console.error);
    }

    async prepare() {

        await ComponentHelper.waitForReady();

        const children = Array.from(this.children) as HTMLImageElement[];

        this.textContent = "....";

        const tasks = [];

        const { default: PhotoSwipeLightBox } = await Modules.import("https://cdn.jsdelivr.net/npm/photoswipe@5.4.4/dist/photoswipe-lightbox.esm.min.js");

        this.innerHTML = "<progress/>";

        const progress = this.querySelector("progress") as HTMLProgressElement;
        progress.max = children.length;
        progress.value = 0;

        this.className = "pswp-gallery";

        const elements = [];

        // remove all children...
        for (const iterator of children) {
            const a = document.createElement("a");
            let full = (iterator as any).src as string;
            let thumb = full;
            const set = iterator.getAttribute("data-src-set")
                ?? iterator.getAttribute("data-srcset")
                ?? iterator.getAttribute("srcset");
            if (set) {
                const srcSet = set
                    .split(",")
                    .map((x) => x.trim().split(" "))
                    .sort(([aSrc, aSize], [bSrc, bSize]) => Number.parseFloat(bSize)-Number.parseFloat(aSize));
                full = srcSet[0][0];
                thumb = srcSet.pop()[0];
            }
            a.href = full;
            // const img = document.createElement("img");
            // img.src = thumb;
            a.appendChild(iterator);
            a.target = "_blank";
            // a.setAttribute("data-pswp-width", "2000");
            // a.setAttribute("data-pswp-height", "2000");
            elements.push(a);

            tasks.push((async () => {
                await new Promise<any>((resolve, reject) => {
                    if (iterator.naturalHeight > 0 && iterator.naturalWidth > 0) {
                        resolve(null);
                        return;
                    }
                    iterator.onload = resolve;
                });

                const width = window.visualViewport.width * 3;
                const height = Math.ceil(iterator.naturalHeight * (width / iterator.naturalWidth)); 
                

                a.setAttribute("data-pswp-width", width as any);
                a.setAttribute("data-pswp-height", height as any);
                progress.value++;
            })())
        }

        await Promise.all(tasks);

        progress.remove();

        for (const iterator of elements) {
            this.appendChild(iterator);
        }

        // wait for all tasks...

        setTimeout(() => {
            const lightBox = new PhotoSwipeLightBox({
                gallery: "#" + this.id,
                children: "a",
                pswpModule: () => Modules.import("https://cdn.jsdelivr.net/npm/photoswipe@5.4.4/dist/photoswipe.esm.js")
            });
            lightBox.init();
        },1);
    }

}

customElements.define("image-grid", ImageGrid);