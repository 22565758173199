import ScreenApi from "../../ScreenApi";
import ScriptInstaller from "../../core/ScriptInstaller";
import { XNode } from "../../core/XNode";
import { styled } from "../../core/core";

    styled.css `
    display: inline-block;
    position: relative;
    min-width: 100px;
    min-height: 100px;

    &::part(frame) {
        border: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    & > * {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        max-height: inherit;
        max-width: inherit;
    }
`.installGlobal("video-player");

const videoScript = ScriptInstaller.install("https://vjs.zencdn.net/8.12.0/video.min.js");

declare let videojs: any;

class VideoPlayer extends HTMLElement {

    source: string;
    autoplay: string;
    youtube: string;
    preload: string;
    controls: string;
    vimeo: string;
    embed: string;
    poster: string;
    muted: string;
    player: any;

    connectedCallback() {
        setTimeout(() => this.init(), 1);
    }

    init() {

        this.source = this.getAttribute("source") ?? this.getAttribute("src");
        this.youtube = this.getAttribute("youtube");
        this.embed = this.getAttribute("embed");
        this.poster = this.getAttribute("poster");
        this.controls = this.getAttribute("controls") ?? "true";
        this.preload = this.getAttribute("preload") ?? "metadata";
        this.muted = this.getAttribute("muted");
        this.autoplay = this.getAttribute("autoplay");

        if (!this.source) {
            const root = this.attachShadow({ mode: "open"});
            if (!this.embed) {
                const { youtube } = this;
                if (youtube) {
                    if (youtube.startsWith("https://") || youtube.startsWith("//")) {
                        this.embed = youtube;
                    } else {
                        this.embed = `https://www.youtube.com/embed/${youtube}`;
                    }
                }
            }

            if (this.embed) {
                XNode.render(root, <div>
                    <iframe
                        src={this.embed}
                        part="frame"/>
                </div>);
                return;        
            }
            this.textContent = "No Source Set";
            return;
        }

        XNode.render(this, <div><video part="video"></video></div>);
        const v = this.querySelector("video") as HTMLVideoElement;
        v.src = this.source;
        if (this.autoplay) {
            this.muted = "true";
            ScreenApi.waitForVisible(v).then(() => {
                v.autoplay = true;
                this.player.play();
            });
        }
        if (this.muted === "true") {
            v.muted = true;
        }
        if (this.preload) {
            v.preload = this.preload as any;
        }
        if (this.controls === "true") {
            v.controls = true;
        }
        v.className = "video-js";
        if (this.poster) {
            v.poster = this.poster;
        }
        videoScript.then(() => {
            this.player = videojs(this.querySelector("video"));
        });

    }

    disconnectedCallback() {
        this.player?.dispose();
    }

    
}

customElements.define("video-player", VideoPlayer);
