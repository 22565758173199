import "./components/carousel-slider/CarouselSlider";
import "./components/typewriter/TypeWriter";
import "./components/image-gallery/ImageGallery";
import "./components/image-grid/ImageGrid";
import "./components/video-player/VideoPlayer";

const installCss = (prefix, remainingUrl) => {
    for(const link of Array.from(document.querySelectorAll(`link[rel=stylesheet]`))) {
        const href = link.getAttribute("href");
        if(href.startsWith(prefix)) {
            return;
        }
    }
    const css = document.createElement("link");
    css.rel = "stylesheet";
    css.href = prefix + remainingUrl;
    document.head.appendChild(css);
};

installCss("https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free", "@6.5.2/css/all.min.css");
installCss("https://vjs.zencdn.net/", "8.12.0/video-js.css");
installCss("https://cdn.jsdelivr.net/npm/photoswipe", "@5.4.4/dist/photoswipe.css");
